import polygon1 from './img/polygon-1.svg'
import facebook from './img/Facebook.png';
import telegram from './img/Telegram.png';
import youtube from './img/Youtube.png';
import instagram from './img/Instagram.png';
import linkedin from './img/LinkedIn.png';
import union from './img/union.svg'
import union1 from './img/union-1.svg'
import vector3 from './img/vector-3.svg'
import vector4 from './img/vector-4.svg'
import danilo from './img/Danilo.jpg'
import uwe from './img/Uwe.jpg'
import yahyah from './img/Yahyah.jpg'
import vector from './img/vector.svg'
import screenshot from './img/screenshot.jpg'
import './App.css';
import {useState} from "react";

function App() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [show, setShow] = useState(false)
  const handleJoin = () => {
      fetch("/api/v1/waitlist", {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({
              name,
              email
          })
      }).then((res) => {
          if (!res.ok)
              return
          setShow(true)
          let wait = new Promise(resolve => setTimeout(resolve, 3 * 1000, "value"))
          wait.then(() => setShow(false))
      })
  }
  return (
    <div className="App">
        <div className="Hero">
            <div className="HeroSection">
                <h1>
                    Unleash the power of Generative A.I. to automate your mail & drive revenue
                </h1>
                <p>
                    Streamline operations, enhance customer analytics, and supercharge your email strategy, all with one
                    platform.
                </p>
                <div>
                    <a href="#waitlist" className="BTN BFW">
                        Join to our waitlist
                    </a>
                    <button>
                        <img src={polygon1} alt="F" className="BI"/>
                        Watch Tour
                    </button>
                </div>
            </div>
        </div>
        <div id="features" className="SUM">
            <h2>
                Features & Benefits
            </h2>
            <h3>
                Automate your mail using the latest in A.I. technology to focus on your time
            </h3>
        </div>
        <img className="SCS" src={screenshot} alt="F"/>
        <div className="FEA">
            <div className="FL">
                <img src={union} alt="F"/>
                <h4>
                    Automated Email Management
                </h4>
                <h5>
                    From generative content creation to order extraction, automate the complete email lifecycle.
                </h5>
            </div>
            <div className="FR">
                <img src={vector4} alt="F"/>
                <h4>
                    AI-driven Customer Analytics
                </h4>
                <h5>
                    Dive deep into customer data for unparalleled insights, all powered by cutting-edge AI algorithms.
                </h5>
            </div>
            <div className="FL">
                <img src={vector3} alt="F"/>
                <h4>
                    End-to-end Dashboard
                </h4>
                <h5>
                    A centralized hub for all your operations, analytics, and more, offering a holistic view of your
                    business activities.
                </h5>
            </div>
            <div className="FR">
                <img src={union1} alt="F"/>
                <h4>
                    Efficient Task Management
                </h4>
                <h5>
                    Stay ahead of your tasks with an integrated job manager, ensuring nothing slips through the cracks.
                </h5>
            </div>
        </div>
        <div className="SUM">
            <h2>
                MEET OUR TEAM
            </h2>
            <h3 style={{maxWidth: 875}}>
                Meet our esteemed team member who are highly accomplished individuals, Hailing from renowned institutes
                across the globe.
            </h3>
        </div>
        <div className="TIM">
            <div className="PRS">
                <img src={danilo} alt="Danilo"/>
                <h6>
                    Danilo Schmidt
                </h6>
                <p>
                    Founder & CEO
                </p>
            </div>
            <div className="PRS">
                <img src={uwe} alt="Uwe"/>
                <h6>
                    Uwe Kasper
                </h6>
                <p>
                    Managing Director
                </p>
            </div>
            <div className="PRS">
                <img src={yahyah} alt="Yahyah"/>
                <h6>
                    Yahyah Pandor
                </h6>
                <p>
                    Executive Advisor
                </p>
            </div>
        </div>
        <div id="waitlist" className="SUM">
            <h2>
                JOIN TO OUR WAitList
            </h2>
            <h3 style={{maxWidth: 875, marginLeft: 12, marginRight: 12}}>
                Join our exclusive waitlist and be first in line for an extraordinary journey!
            </h3>
            <div className="ING">
                <input className="INP1" placeholder="Enter your full name"
                  value={name} onChange={(e) => setName(e.target.value)}
                />
                <hr/>
                <input className="INP2" placeholder="example@email.com"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />
                <div className="ELB">
                    <hr/>
                    <button onClick={handleJoin} className="BFB">
                        Join to our waitlist
                        <img src={vector} alt="F"/>
                    </button>
                </div>
            </div>

        </div>
        <div className="SOC">
            <img src={facebook} alt="F"/>
            <img src={telegram} alt="F"/>
            <img src={youtube} alt="F"/>
            <img src={instagram} alt="F"/>
            <img src={linkedin} alt="F"/>
        </div>
        <p className="CR">
            Copyright © 2023 AIDU. All rights reserved.
        </p>
        <div className='Overlay'>
            <p className='Logo'>AIDU</p>
            <div className='NavG'>
                <a href="#features" className='Nav'>Features</a>
                {/*<p className='Nav'>Careers</p>*/}
                {/*<p className='Nav'>Contact</p>*/}
            </div>
            <a href="#waitlist" className='BTN NavB'>
                Join to our waitlist
            </a>
        </div>
        <div className="SB" style={{display: show ? "block" : "none"}}>
            submitted!
        </div>
    </div>
  );
}

export default App;
